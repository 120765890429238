import Vue from 'vue';
import EnsoCarousel from 'enso-carousel';
import MainMenu from './enso/MainMenu.js';

new Vue({
  el: '#app',
  components: {
    EnsoCarousel,
    MainMenu,
  },
});

document.querySelectorAll('form input[type="checkbox"]:not(.w-checkbox-input)').forEach(function (checkbox) {
  checkbox.addEventListener('change', function (event) {
    if (event.currentTarget.checked) {
      event.currentTarget.parentNode.querySelector('.w-checkbox-input').classList.add('w--redirected-checked');
    } else {
      event.currentTarget.parentNode.querySelector('.w-checkbox-input').classList.remove('w--redirected-checked');
    }
  });
});

document.querySelectorAll('.menubtn').forEach(function (element) {
    element.addEventListener('click', function (event) {
        document.querySelectorAll('.mobilenav').forEach(function (mobilenav) {
            mobilenav.classList.add('block');
        });
    });
});

document.querySelectorAll('.closebtn').forEach(function (element) {
    element.addEventListener('click', function (event) {
        document.querySelectorAll('.mobilenav').forEach(function (mobilenav) {
            mobilenav.classList.remove('block');
        });
    });
});
